import { Center, CloseButton, Loader, Tabs } from "@mantine/core";
import { IconClock, IconReceipt, IconReceiptRefund } from "@tabler/icons";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { FinanceableApi } from "../../apis";
import { message } from "../../utils/Message";
import { TabInvoice } from "./tab-invoice";
import { TabRequest } from "./tab-request";
import { TabUseageLimit } from "./tab-useage-limit";
import { FormLayout } from "../layout";
import { useParams } from "react-router";

type ITabKey = "invoice" | "request" | "useage_limit";

export const DrawerFormBuyer = ({ setAction, action, reloadTable }: { action: any; setAction: (e: any) => void; reloadTable: () => void }) => {
  const [requestedAmount, setRequestedAmount] = useState<number | undefined>(0);
  const [tabKey, setTabKey] = useState<ITabKey>("invoice");
  const formRef = useRef<any>(null);
  const params = useParams();
  const { data, isLoading } = useSWR<any>(
    `swr.api.financeable_scf.${action[1]?.id}.program.${params.id}`,
    async () => {
      if (action[1]?.id && params.id) {
        try {
          const res = await FinanceableApi.getScf(action[1]?.id!, params.id!);
          return res;
        } catch (error: any) {
          message.error(error?.message);
        }
      } else return null;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  useEffect(() => {
    setRequestedAmount(data?.amountToPay || 0);
  }, [data]);

  console.log("=========================>");
  console.log("data: ", data);
  console.log("action: ", action);
  console.log("<=========================");

  if (isLoading)
    return (
      <Center my={"lg"}>
        <Loader />
      </Center>
    );
  else
    return (
      <div>
        <FormLayout
          my={0}
          title="Санхүүжилтийн хүсэлт"
          subTitle={"Buyer-LED SCF хөтөлбөрөөр санхүүжилтийн хүсэлт батлах"}
          extra={[<CloseButton onClick={() => setAction([])} key={0} />]}>
          <Tabs
            value={tabKey}
            onTabChange={(e: ITabKey) => {
              setTabKey(e);
            }}>
            <Tabs.List>
              <Tabs.Tab value="invoice" icon={<IconReceipt size={18} />} py={"md"}>
                Нэхэмжлэх
              </Tabs.Tab>
              <Tabs.Tab value="request" icon={<IconReceiptRefund size={18} />} py={"md"}>
                Хүсэлт
              </Tabs.Tab>
              <Tabs.Tab value="useage_limit" icon={<IconClock size={18} />} py={"md"}>
                Лимит ашиглалт
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="invoice" pt="xs">
              <TabInvoice data={data} />
            </Tabs.Panel>

            <Tabs.Panel value="request" pt="xs">
              <TabRequest reloadTable={reloadTable} programId={action[2]} selectedData={data} formRef={formRef} setRequestedAmount={setRequestedAmount} />
            </Tabs.Panel>

            <Tabs.Panel value="useage_limit" pt="xs">
              <TabUseageLimit
                onSubmit={async () => {
                  let res = await formRef?.current?.submit();

                  if (res) {
                    setAction([]);
                  } else {
                    message.error("Талбарыг гүйцэт бөглөнө үү. ");
                    setTabKey("request");
                  }
                }}
                selectedData={data}
                requestedAmount={requestedAmount}
              />
            </Tabs.Panel>
          </Tabs>
        </FormLayout>
      </div>
    );
};
