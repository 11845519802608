import { Badge, Divider, Flex, Grid, Input, LoadingOverlay, Paper, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { ScfRequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { CheckboxField } from "../form/check-box";
import { NumberCurrencyField } from "../form/number-currency-field";
import { FileUpload } from "../upload/file-upload";

const schema = yup.object({
  requestedAmount: yup.number()?.moreThan(0, "0-с их үнэ байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  termsOfContract: yup.boolean().required("Заавал бөглөнө!").oneOf([true], "Заавал бөглөнө!"),
  contractFile: yup.string().optional().nullable(),
});

type IBuyerLedCreate = {
  programId: string;
  invId: string;
  requestedAmount: number;
  contractFile: string | null;
  termsOfContract: boolean;
};

export function TabRequest({
  selectedData,
  formRef,
  setRequestedAmount,
  programId,
  reloadTable,
}: {
  selectedData: any;
  formRef: any;
  programId: string;
  setRequestedAmount: (e: number) => void;
  reloadTable: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id: programId2 = "" } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = React.useState<IBuyerLedCreate>({
    requestedAmount: selectedData?.amountToPay || 0,
    contractFile: null,
    invId: selectedData?.id,
    programId: programId || programId2,
    termsOfContract: false,
  });

  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { scfRequestTypes, scfRequestStatus, currencies, productBuyerFeeRules, productBuyerPenaltyTypes, repaymentOverDueStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const onSubmit = async (values: IBuyerLedCreate | any) => {
    setLoading(true);
    try {
      await ScfRequestApi.buyerLedCreate({
        programId: values.programId || programId2,
        invId: values.invId,
        requestedAmount: values.requestedAmount,
        contractFiles: [values.contractFile],
      });
      await reloadTable();
      setLoading(false);
      message.success("Амжилттай илгээлээ");
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  const booleanIcon = (min: number, mid: number, max: number) => {
    switch (true) {
      case min > mid || mid > max: {
        return <IconX size={20} color="red" />;
      }
      case min <= mid && mid <= max: {
        return <IconCheck size={20} color="green" />;
      }

      default: {
        return "-";
      }
    }
  };

  const booleanIcon2 = (min: number, mid: number) => {
    if (min >= mid) {
      return <IconCheck size={20} color="green" />;
    } else {
      return <IconX size={20} color="red" />;
    }
  };

  const checkRequimentIcon = (val1: number, val2: number) => {
    if (val1 < val2) {
      return <IconX size={20} color="red" />;
    } else return <IconCheck size={20} color="green" />;
  };

  const totalFee = ({ minFee, values }: { minFee: number; values: any }) => {
    // Тооцсон шимтгэл
    // moment(selectedData?.paymentDate).diff(moment(), "days")
    let TootssonFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? (parseFloat(`${(selectedData?.program?.product?.suppFee / selectedData?.program?.product?.suppCalculateDay || 0) / 100}`) || 0) *
          (diffDay || 0) *
          (values.requestedAmount || 0)
        : ((selectedData?.program?.product?.buyerFee / selectedData?.program?.product?.buyerCalculateDay || 0) / 100) *
          (values.requestedAmount || 0) *
          (selectedData?.program?.product?.buyerTerm || 0);

    // Өргөдлийн хураамж
    let orgodliinHuraamj =
      user?.currentBusiness?.type === "SUPPLIER" ? selectedData?.program?.product?.suppAppFee || 0 : selectedData?.program?.product?.buyerAppFee || 0;

    // Олголтын шимтгэл
    let olgoltingFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? ((values?.requestedAmount || 0) * (selectedData?.program?.product?.suppDisbursementFee || 0)) / 100 || 0
        : ((values?.requestedAmount || 0) * (selectedData?.program?.product?.buyerDisbursementFee || 0)) / 100 || 0;

    let total = TootssonFee + orgodliinHuraamj + olgoltingFee;

    if (minFee < total) return minFee || 0;
    else return total || 0;
  };

  const totalFeeAmount = ({ minFee, values }: { minFee: number; values: any }) => {
    // Тооцсон шимтгэл
    // moment(selectedData?.paymentDate).diff(moment(), "days")
    let TootssonFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? (parseFloat(`${(selectedData?.program?.product?.suppFee / selectedData?.program?.product?.suppCalculateDay || 0) / 100}`) || 0) *
          (diffDay || 0) *
          (values.requestedAmount || 0)
        : ((selectedData?.program?.product?.buyerFee / selectedData?.program?.product?.buyerCalculateDay || 0) / 100) *
          (values.requestedAmount || 0) *
          (selectedData?.program?.product?.buyerTerm || 0);

    // Өргөдлийн хураамж
    let orgodliinHuraamj =
      user?.currentBusiness?.type === "SUPPLIER" ? selectedData?.program?.product?.suppAppFee || 0 : selectedData?.program?.product?.buyerAppFee || 0;

    // Олголтын шимтгэл
    let olgoltingFee =
      user?.currentBusiness?.type === "SUPPLIER"
        ? ((values?.requestedAmount || 0) * (selectedData?.program?.product?.suppDisbursementFee || 0)) / 100 || 0
        : ((values?.requestedAmount || 0) * (selectedData?.program?.product?.buyerDisbursementFee || 0)) / 100 || 0;

    let total = TootssonFee + orgodliinHuraamj + olgoltingFee;

    if (minFee > total) return minFee || 0;
    else return total || 0;
  };

  const diffDay = moment(selectedData?.paymentDate).diff(moment(), "days");

  return (
    <Form ref={formRef} initialValues={data && data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Grid>
            <LoadingOverlay visible={loading} />
            <Grid.Col span={12}>
              <Divider
                label={
                  <Text color="golomt" fw={600} size={"md"}>
                    Хүсэлтийн мэдээлэл
                  </Text>
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн код:">
                <div>
                  <Text fz="sm" fw={500}>
                    <Text color="gray">Автоматаар үүснэ</Text>
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн төрөл:">
                <div>
                  <Text fz="sm" fw={500}>
                    {scfRequestTypes?.find((f: any) => f?.code === user?.currentBusiness?.type)?.name}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлт гаргасан бизнес:">
                <div>
                  <Text fz="sm" fw={500}>
                    <Flex gap={8} align="center">
                      <Text>{user?.currentBusiness?.profileName || "-"}</Text>
                      <Text color="golomt"># {user?.currentBusiness?.refCode || "-"}</Text>
                    </Flex>
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн огноо:">
                <div>
                  <Text fz="sm" fw={500}>
                    <Text color="gray">Автоматаар үүснэ</Text>
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлт гаргасан хэрэглэгч:">
                <div>
                  <Text fz="sm" fw={500}>
                    {user?.lastName ? user?.lastName[0] + ". " : ""} {user?.firstName}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хүсэлтийн төлөв:">
                <div>
                  <Text fz="sm" fw={500}>
                    {scfRequestStatus.find((item: any) => item.code === "DRAFT")?.name || "Түр төлөв"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хөтөлбөрийн нэр:">
                <Flex gap="xs" wrap={"wrap"} rowGap={"0"}>
                  <Text fz="sm" fw={500}>
                    # {selectedData?.program?.refCode || "-"},
                  </Text>
                  <Text color="dimmed" fz="sm" fw={500}>
                    {selectedData?.program?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                <Flex columnGap="xs" wrap={"wrap"}>
                  <Text fz="sm" fw={500}>
                    # {selectedData?.program?.product?.refCode || "-"}
                  </Text>
                  <Text color="dimmed" fz="sm" fw={500}>
                    {selectedData?.program?.product?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <NumberCurrencyField
                max={selectedData?.amountToPay || 0}
                required
                onChangeCustom={(e: number) => setRequestedAmount(e)}
                name="requestedAmount"
                placeholder="Санхүүжилт олгох дүн"
                label="Санхүүжилт олгох дүн:"
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилтийн валют:">
                <div>
                  <Text fz="sm" fw={500}>
                    {currencies.find((mk: any) => mk.code === selectedData?.program?.currency)?.name || "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилтийн доод, дээд хэмжээ:">
                <Flex style={{ alignItems: "center" }} gap="xs">
                  <>
                    {booleanIcon(
                      (selectedData?.program?.product?.minOfInv * selectedData?.confirmedAmount) / 100,
                      values?.requestedAmount,
                      (selectedData?.program?.product?.maxOfInv * selectedData?.confirmedAmount) / 100,
                    )}
                  </>
                  <Text fz="sm" color="dimmed" fw={500}>
                    {(selectedData?.program?.product?.minOfInv * selectedData?.confirmedAmount) / 100 || "-"} -
                    {(selectedData?.program?.product?.maxOfInv * selectedData?.confirmedAmount) / 100 || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэх доод үлдэгдэл:">
                <Flex align="center" gap={8}>
                  {checkRequimentIcon(selectedData?.amountToPay || 0, selectedData?.program?.product?.minInvAmount || 0)}
                  <Text fz="sm" fw={500}>
                    {currencyFormat(selectedData?.program?.product?.minInvAmount || 0)}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэх Tenor:">
                <Flex gap="xs" align="center">
                  {booleanIcon(
                    selectedData?.program?.product?.minTenor,
                    Math.abs(Number(dayjs(selectedData?.paymentDate).diff(dayjs(), "day"))),
                    selectedData?.program?.product?.maxTenor,
                  )}
                  <Text fz="sm" fw={500}>
                    {selectedData?.program?.product?.minTenor} - {selectedData?.program?.product?.maxTenor}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилт авах хугацаа /хоног/:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "BUYER" ? selectedData?.program?.product?.buyerTerm || 0 + "хоног " : ` ${diffDay} хоног`}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэл тооцсон хувь /хоногт/:">
                <Text fz="sm" fw={500}>
                  {/* {selectedData?.program?.product?.suppCalculateDay + "-"} */}
                  {user?.currentBusiness?.type === "BUYER"
                    ? parseFloat(`${(selectedData?.program?.product?.buyerFee / selectedData?.program?.product?.buyerCalculateDay || 0) / 100}`).toFixed(6)
                    : parseFloat(`${(selectedData?.program?.product?.suppFee / selectedData?.program?.product?.suppCalculateDay || 0) / 100}`).toFixed(6)}{" "}
                  %
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Тооцсон шимтгэл:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "BUYER" ? (
                    <>
                      {currencyFormat(
                        ((selectedData?.program?.product?.buyerFee / selectedData?.program?.product?.buyerCalculateDay || 0) / 100) *
                          (values.requestedAmount || 0) *
                          (selectedData?.program?.product?.buyerTerm || 0),
                      )}
                    </>
                  ) : (
                    currencyFormat(
                      parseFloat(
                        (
                          (parseFloat(`${(selectedData?.program?.product?.suppFee / selectedData?.program?.product?.suppCalculateDay || 0) / 100}`) || 0) *
                          (diffDay || 0) *
                          (values.requestedAmount || 0)
                        ).toFixed(2),
                      ),
                    )
                  )}
                </Text>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Өргөдлийн хураамж:">
                <Text fz="sm" fw={500}>
                  {currencyFormat(
                    user?.currentBusiness?.type === "SUPPLIER"
                      ? selectedData?.program?.product?.suppAppFee || 0
                      : selectedData?.program?.product?.buyerAppFee || 0,
                  )}
                </Text>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Олголтын шимтгэл:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "SUPPLIER"
                    ? currencyFormat(((values?.requestedAmount || 0) * (selectedData?.program?.product?.suppDisbursementFee || 0)) / 100)
                    : currencyFormat(((values?.requestedAmount || 0) * (selectedData?.program?.product?.buyerDisbursementFee || 0)) / 100)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэлийн доод дүн:">
                <Flex gap="xs" align="center">
                  <>
                    {booleanIcon2(
                      user?.currentBusiness?.type === "SUPPLIER"
                        ? selectedData?.program?.product?.suppMinFee || 0
                        : selectedData?.program?.product?.buyerMinFee || 0,
                      totalFee({
                        minFee:
                          user?.currentBusiness?.type === "SUPPLIER"
                            ? selectedData?.program?.product?.suppMinFee || 0
                            : selectedData?.program?.product?.buyerMinFee || 0,
                        values: values,
                      }),
                    )}
                  </>
                  <Text fz="sm" fw={500}>
                    {currencyFormat(
                      user?.currentBusiness?.type === "SUPPLIER"
                        ? selectedData?.program?.product?.suppMinFee || 0
                        : selectedData?.program?.product?.buyerMinFee || 0,
                    )}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нийт шимтгэлийн дүн:">
                <div>
                  <Text fz="sm" fw={500}>
                    {user?.currentBusiness?.type === "BUYER"
                      ? currencyFormat(
                          totalFeeAmount({
                            minFee: selectedData?.program?.product?.buyerMinFee || 0,
                            values: values,
                          }),
                        )
                      : currencyFormat(
                          totalFeeAmount({
                            minFee: selectedData?.program?.product?.suppMinFee || 0,
                            values: values,
                          }),
                        )}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Шимтгэл суутгах дүрэм:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "BUYER"
                    ? productBuyerFeeRules?.find((f: any) => f?.code === selectedData?.program?.product?.buyerFeeRule)?.name || "-"
                    : productBuyerFeeRules?.find((f: any) => f?.code === selectedData?.program?.product?.suppFeeRule)?.name || "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Эргэн төлөх хугацаа:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.paymentDate ? dateTimeFormat(selectedData?.paymentDate) : "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Буцаан дуудах эсэх:">
                <div>
                  {selectedData?.program?.product?.recourseTerm ? (
                    <Badge variant="filled" color="blue" radius={4}>
                      Тийм
                    </Badge>
                  ) : (
                    <Badge variant="filled" color="gray" radius={4}>
                      Үгүй
                    </Badge>
                  )}
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Буцаан дуудах огноо:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.program?.product?.recourseTerm
                      ? dayjs(dayjs(selectedData?.paymentDate).add(selectedData?.program?.product?.recourseDays, "day")).format("YYYY-MM-DD HH:mm") ||
                        "_-_-_ _:_"
                      : "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацаа хэтрэх алданги:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "BUYER"
                    ? selectedData?.program?.product?.buyerPenaltyPercent || "-"
                    : selectedData?.program?.product?.suppPenaltyPercent || "-"}{" "}
                  %
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Алдангийн арга:">
                <Text fz="sm" fw={500}>
                  {user?.currentBusiness?.type === "BUYER"
                    ? productBuyerPenaltyTypes.find((item: IReference) => item.code === selectedData?.program?.product?.buyerPenaltyType)?.name || "-"
                    : productBuyerPenaltyTypes.find((item: IReference) => item.code === selectedData?.program?.product?.suppPenaltyType)?.name || "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Нэхэмжлэхийн хугацаа:">
                <div>
                  <Text fz="sm" fw={500}>
                    {Math.abs(Number(dayjs(selectedData?.confirmedDate).diff(dayjs(), "day")))}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацааны шалгуур:">
                <Flex align="center" gap="xs">
                  {booleanIcon(
                    selectedData?.program?.product?.minDays,
                    Math.abs(Number(dayjs(selectedData?.confirmedDate).diff(dayjs(), "day"))),
                    selectedData?.program?.product?.maxDays,
                  )}
                  <Text fz="sm" fw={500}>
                    {selectedData?.program?.product?.minDays} - {selectedData?.program?.product?.maxDays}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Хугацаа хэтрэлт:">
                <Flex gap="xs" align="center">
                  {selectedData?.overdueStatus === "NORMAL" ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                  <Text fz="sm" fw={500} color="dimmed">
                    {repaymentOverDueStatus?.find((item: IReference) => item.code === selectedData?.overdueStatus)?.name || "-"}
                  </Text>
                </Flex>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Санхүүжилт авах данс:">
                <div>
                  <Text fz="sm" fw={500}>
                    {selectedData?.businessInAcc?.name || "-"}, {selectedData?.businessInAcc?.number || "-"}
                  </Text>
                </div>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <FileUpload
                styles={{ input: { maxWidth: "320px" } }}
                error={!values?.contractFile && errors?.contractFile}
                onChange={(e) => setFieldValue("contractFile", e?.url)}
                name="contractFile"
                placeholder={values?.contractFile ? `File: ${values?.contractFile?.slice(0, 20)}...` : "Хуулах"}
                label="Хавсралт"
                nullable
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <CheckboxField name="termsOfContract" label="Гэрээ баталгаажилт:" children="Тийм" required />
            </Grid.Col>

            <Grid.Col sm={12} md={6} lg={4}>
              <Input.Wrapper label="Гэрээ баталгаажилт:">
                <Paper pt={"4px"}>
                  {values.termsOfContract ? (
                    <Badge color="green" variant="filled">
                      Баталгаажсан
                    </Badge>
                  ) : (
                    <Badge color="red" variant="filled">
                      Баталгаажаагүй
                    </Badge>
                  )}
                </Paper>
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
