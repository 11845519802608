import { Header as BaseHeader, Box, Burger, Center, Flex, Group, Text, createStyles } from "@mantine/core";
import { ReactComponent as Golomt } from "../../assets/golomt.svg";
import { useScreenDetector } from "../screen-detector";

export const HeaderLogin = ({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) => {
  const { classes } = useStyles();
  const { isMobile, isTablet } = useScreenDetector();

  return (
    <BaseHeader height={{ base: 68, md: 68 }} className={classes.headerWrap}>
      <Group className={classes.header}>
        {!isMobile ? (
          <Flex align={"center"} gap={8}>
            <a href="https://cp.dehub.mn" className={classes.logoBox}>
              <Center className={classes.logo}>
                <Golomt />
              </Center>
            </a>
            {!isTablet && (
              <>
                <Box h={"32px"} style={{ borderRight: "1px solid #868E96" }}></Box>
                <Text className={classes.moduleText}>САНХҮҮЖИЛТ УДИРДЛАГА</Text>
              </>
            )}
          </Flex>
        ) : (
          <div>
            <Flex p={"xs"}>
              <Burger color="gray" size={"sm"} opened={opened} onClick={() => setOpened(!opened)} />
            </Flex>
          </div>
        )}
      </Group>
    </BaseHeader>
  );
};

const useStyles = createStyles((theme) => ({
  headerWrap: {
    background: "#041139",
    paddingBottom: 4,
    borderBottom: 0,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: "#041139",
    height: 64,
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
    gap: "12px",
  },
  logo: {
    height: 30,
  },
  logoText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.white,
  },
  user: {
    padding: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "8px",
    justifyContent: "space-between",
    color: "#fff",
    "&:hover": {
      opacity: 0.4,
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  moduleText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.colors.golomt[5],
    textTransform: "uppercase",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    height: "90px",
    transition: "box-shadow 150ms ease, transform 100ms ease",
    ":hover": {
      boxShadow: theme.shadows.sm,
    },
  },
}));
