import { ActionIcon, Anchor, Avatar, Group, Text, Tooltip } from "@mantine/core";
import { IconFileText } from "@tabler/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ScfProgramApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const SupplierSubcontractList = () => {
  const navigate = useNavigate();
  const { programStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    programStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          navigate(`/finance-program/${item.id}`);
          break;

        default:
          break;
      }
    },
  });

  return (
    <Table
      minWidth={2400}
      name="requests.list.supplier.led"
      columns={columns}
      filters={{ financeType: "LBF" }}
      loadData={(data) => ScfProgramApi.list(data!)}
    />
  );
};

const useHeader = ({ onClick, programStatus }: { onClick: (e1: string, e2: any) => void; programStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
            <IconFileText />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.name || "-"}</Text>
          <Anchor># {record?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.product?.name || "-"}</Text> <Anchor># {record?.product?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    render: (record) => {
      return Tag(
        programStatus.find((item: IReference) => item.code === record.programStatus)?.color || "",
        programStatus.find((item: IReference) => item.code === record.programStatus)?.name || "-",
      );
    },
  },
  {
    title: "Анкор партнер",
    render: (record) => {
      return record?.anchor?.partner ? (
        <Group w={"max-content"}>
          <div>
            <Text>{record?.anchor?.partner?.businessName || "-"}</Text> <Anchor># {record?.anchor?.partner?.refCode || "-"}</Anchor>
          </div>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    render: (record) => {
      return record?.anchor?.refCode ? (
        <Group w={"max-content"}>
          <div>
            <Text>{record?.anchor?.profileName || "-"}</Text> <Anchor># {record?.anchor?.refCode || "-"}</Anchor>
          </div>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Нийлүүлэгчийн санхүү",
    render: (record) => {
      return record?.anchor?.finUser ? (
        <Group w={"max-content"}>
          <Avatar src={record?.anchor?.finUser?.avatar} size={32} />
          <div>
            <Text>
              {record?.anchor?.finUser?.lastName ? record?.anchor?.finUser?.lastName[0] + ". " : ""}
              {record?.anchor?.finUser?.firstName}{" "}
            </Text>{" "}
            <Text color="gray" fz={"xs"}>
              {record?.anchor?.finUser?.phone}
            </Text>
          </div>
        </Group>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Баталсан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record?.programLimit || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Боломжит лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.availableAmount)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Ашигласан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.utilizedAmount)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Олголт хүлээж буй",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.toDisburseAmount)}
        </Text>
      </Group>
    ),
  },
];
