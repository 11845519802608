import { Box, Button, Grid, Group, Input, Paper, Space, Text } from "@mantine/core";
import { IconCopy } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { lbfApi } from "../../apis";
import { IAccounts } from "../../interfaces/IAccounts";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { Tag } from "../tags";

type IForm = { lbfAccount: string | undefined; accountId: string | undefined; amount: number | undefined };

export const PayForm = ({ onClose, data }: { onClose: any; data: any }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAccount, setSelectedAccount] = useState<IAccounts | undefined>();

  const { bankAccounts, invoiceOverdueStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const payOffAmount = parseFloat(data?.payOffAmount);

  const onSubmit = async (values: IForm) => {
    setIsLoading(true);
    try {
      await lbfApi.pay(values);
      message.success(`Зээлийн төлөлт амжилттай хийгдлээ. Банк таны зээлийн суутгалыг хийх болно. ${dateTimeFormat(new Date())} `);
      onClose();
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй.");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Form
        validationSchema={yup.object({
          amount: yup
            .number()
            .min(0, "0-с их дүн оруулна уу!")
            .max(1000000000, "1,000,000,000-с бага дүн оруулна уу!")
            .typeError("Заавал бөглөнө!")
            .required("Заавал бөглөнө!")
            .nullable(),
          lbfAccount: yup.string().required("Заавал бөглөнө!").nullable(),
          accountId: yup.string().required("Заавал бөглөнө!").nullable(),
        })}
        initialValues={{
          lbfAccount: data?.accountNumber,
          accountId: undefined,
          amount: undefined,
        }}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, errors }) => {
          return (
            <div>
              <Paper withBorder p={"lg"}>
                <Grid>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Зээлийн данс">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {data?.accountNumber}
                        </Text>
                        <Text fz={"sm"} component="span">
                          , {data?.accountName}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Төлбөл зохих">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {currencyFormat(payOffAmount)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Үндсэн төлбөрийн дүн">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {currencyFormat(Math.abs(data?.liabliltyAmount))}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Хүүгийн төлбөр">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          -
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Торгуулийн хүү">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {currencyFormat(data?.overdueAmount || 0)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>{" "}
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Хугацаа хэтэрсэн хоног">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {Tag(
                            invoiceOverdueStatus.find((item: any) => item.code === data?.invoice?.overdueStatus)?.color || "blue",
                            invoiceOverdueStatus.find((item: any) => item.code === data?.invoice?.overdueStatus)?.name || "-",
                          )}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Дараагийн суутгал хийх огноо">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {data?.nextInstallmentDueDate}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Суутгалын дүн">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {currencyFormat(data?.liabliltyAmount || 0)}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Сүүлийн эргэн төлөлтийн огноо">
                      <Box w="max-content">
                        <Text fz={"sm"} component="span" color="golomt">
                          {data?.maturityDate}
                        </Text>
                      </Box>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col>
                    <Text fz={"md"} fw={600}>
                      Төлөх төлбөрийн мэдээлэл
                    </Text>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Төлбөр төлөх данс">
                      <SelectField
                        name="accountId"
                        onChange={(e: any) => {
                          setSelectedAccount(bankAccounts.find((item: any) => item.id === e));
                        }}
                        clearable
                        searchable
                        placeholder="Дансны дугаар, дансны нэр"
                        options={bankAccounts.map((item: IAccounts, index: number) => {
                          return {
                            value: item.id,
                            label: `${item.name} / ${item.number}`,
                          };
                        })}
                      />
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <NumberCurrencyField label="Төлбөр төлөх дүн" name="amount" placeholder="Төлбөр төлөх дүн" />
                  </Grid.Col>
                  <Grid.Col sm={12} md={6} lg={4}>
                    <Input.Wrapper label="Нийт дүнгээр төлөх">
                      <div>
                        <Button
                          onClick={() => {
                            setFieldValue("amount", payOffAmount);
                          }}
                          variant="light"
                          leftIcon={<IconCopy size={"1.2rem"} />}>
                          {currencyFormat(payOffAmount)}
                        </Button>
                      </div>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h={"lg"} />
              <Group position="right">
                <Button onClick={() => onClose()} variant="default">
                  Болих
                </Button>
                <Button loading={isLoading} type="submit">
                  Төлбөр батлах
                </Button>
              </Group>
            </div>
          );
        }}
      </Form>
    </div>
  );
};
