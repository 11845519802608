import { Anchor, Avatar, Button, Center, CloseButton, Divider, Flex, Grid, Group, Input, Paper, Text, Textarea } from "@mantine/core";
import { IconBarcodeOff, IconCoins, IconFile } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RepaymentApi } from "../../apis";
import { IAccounts } from "../../interfaces/IAccounts";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { FileUpload } from "../upload/file-upload";

const schema = yup.object({
  amount: yup
    .number()
    .min(0, "0-с их дүн оруулна уу!")
    .max(1000000000, "1,000,000,000-с бага дүн оруулна уу!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
  method: yup.string().required("Заавал бөглөнө!").nullable(),
  addInfo: yup.string().required("Заавал бөглөнө!").nullable(),
  creditAccountId: yup
    .string()
    .when("method", {
      is: "B2B",
      then: yup.string().required("Заавал бөглөнө!").nullable(),
    })
    .optional(),
  files: yup.string().optional().nullable(),
});

type IForm = {
  amount: number;
  method: string;
  creditAccountId: string;
  addInfo: string;
  files: string[];
};

export function PayForm({ action, setAction, reload }: { action: any; setAction: any; reload: any }) {
  const [selectedAccount, setSelectedAccount] = useState<IAccounts | undefined>();
  const [loading, setloading] = useState<boolean>(false);
  const [data] = useState<IForm>({
    amount: undefined,
    method: undefined,
    creditAccountId: undefined,
    addInfo: undefined,
    files: undefined,
    ...(action[1] && action[1]),
  });
  const { bankAccounts } = useSelector((state: { general: IGeneral }) => state.general);
  const [qpay, setQpay] = useState<any>({ success: false });

  const onSubmit = async (value: IForm) => {
    setloading(true);
    try {
      console.log({
        amount: value?.amount,
        method: value?.method,
        creditAccountId: value?.creditAccountId,
        addInfo: value?.addInfo,
        files: value?.files ? [value?.files] : [],
      });

      let res = await RepaymentApi.pay(`${action[1] && action[1].id}`, {
        amount: value?.amount,
        method: value?.method,
        creditAccountId: value.method === "QPAY" ? undefined : value?.creditAccountId,
        addInfo: value?.addInfo,
        files: value?.files ? [value?.files] : null,
      });

      if (value.method === "QPAY") {
        console.log("method: QPAY ===>");
        console.log("res:", res);

        setQpay({ ...res, success: true });
      } else {
        if (res?.url) {
          message.success(`нэхэмжлэх амжилттай төлөгдлөө. ${dateTimeFormat(new Date())}`);
          // let win: any = window.open(`${res?.url}`, "Та банкаар нэвтэрнэ  үү", "height=800,width=700");
          // navigate("/buyer-invoice");
          window.location.href = `${res?.url}`;
          reload();
        }
      }
    } catch (error: any) {
      message.error(error.message);
    }
    setloading(false);
  };

  return (
    <Paper px="lg">
      <FormLayout
        title="Төлбөрийн мэдээлэл"
        subTitle="Нэхэмжлэхийн төлбөр төлөх мэдээлэл"
        extra={[
          <CloseButton
            onClick={() => {
              setAction([]);
            }}
            key={0}
          />,
        ]}>
        <Form validationSchema={schema} initialValues={data} onSubmit={onSubmit}>
          {({ values, setFieldValue, errors }) => {
            return (
              <>
                <Grid>
                  <Grid.Col span={4}>
                    <Group position="right" align="center" h={"36px"}>
                      <Text size="sm" color="gray.9" fw={500}>
                        Төлбөрийн хэрэгсэл:
                      </Text>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      disabled={qpay.success}
                      searchable
                      clearable
                      placeholder="Сонгох"
                      name="method"
                      options={[
                        {
                          code: "B2B",
                          name: "B2B тооцооны данснаас",
                        },
                        {
                          code: "QPAY",
                          name: "QPay",
                        },
                      ]?.map((item: IReference | any) => {
                        return {
                          value: item.code,
                          label: item.name,
                        };
                      })}
                      required
                      onChange={() => {
                        setFieldValue("creditAccountId", undefined);
                      }}
                    />
                  </Grid.Col>

                  {qpay.success ? (
                    <>
                      <>
                        <Grid.Col span={12}>
                          <Center>
                            <Avatar size={309} src={"data:image/jpeg;base64," + qpay?.qr_image || ""} typeof="base64">
                              <IconBarcodeOff />
                            </Avatar>
                          </Center>
                          <Center>
                            <Text mt={"md"} maw={309}>
                              Та доорх QR кодыг банк апп-р уншуулах эсвэл банкаа сонгож АПП-р төлнө уу.
                            </Text>
                          </Center>
                          <Divider mt={"xs"} />
                        </Grid.Col>
                        <Grid.Col>
                          <Group position="right">
                            <Button
                              loading={loading}
                              onClick={async () => {
                                await reload();
                                setAction([]);
                              }}>
                              Болсон
                            </Button>
                          </Group>
                        </Grid.Col>
                      </>
                    </>
                  ) : (
                    <>
                      <>
                        <>
                          <Grid.Col span={4}></Grid.Col>
                          {values.method !== "QPAY" && (
                            <>
                              <Grid.Col span={4}>
                                <Group position="right" align="center" h={"36px"}>
                                  <Text size="sm" color="gray.9" fw={500}>
                                    Шилжүүлэх данс:
                                  </Text>
                                </Group>
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <SelectField
                                  searchable
                                  clearable
                                  placeholder="Сонгох"
                                  name="creditAccountId"
                                  onChange={(e: any) => {
                                    setSelectedAccount(bankAccounts.find((item: any) => item.id === e));
                                  }}
                                  options={bankAccounts.map((item: IAccounts, index: number) => {
                                    return {
                                      value: item.id,
                                      label: `${item.name} / ${item.number}`,
                                    };
                                  })}
                                  required
                                />
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <Group align="center" h={"36px"}>
                                  <Text size="sm" color="gray.9" fw={500}>
                                    Дансны үлдэгдэл: {selectedAccount?.balance || 0} ₮
                                  </Text>
                                </Group>
                              </Grid.Col>
                            </>
                          )}
                          <Grid.Col span={4}>
                            <Group position="right" align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Хүлээн авах данс:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Input
                              value={`${values?.receiverAcc?.number}, ${values?.receiverAcc?.bankName}`}
                              readOnly
                              placeholder="Автоматаар гарна"
                              required
                            />
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Group align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                {values?.receiverAcc?.name}
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Group position="right" align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Гүйлгээний утга:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Textarea readOnly placeholder="Автоматаар гарна" value={values?.refCode} required />
                          </Grid.Col>
                          <Grid.Col span={4}></Grid.Col>
                          <Grid.Col span={4}>
                            <Group position="right" align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Төлөх дүнг оруулна уу:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <NumberCurrencyField placeholder="00.00" name="amount" required />
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Group align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Үлдэх төлбөр: {currencyFormat((values.amountToPay || 0) - (values.amount || 0))}
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Group position="right" align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Нэмэлт тэмдэглэл:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <TextareaField placeholder="Нэмэлт тэмдэглэл" name="addInfo" max={255} required />
                          </Grid.Col>
                          <Grid.Col span={4}></Grid.Col>
                          <Grid.Col span={4}>
                            <Group position="right" align="center" h={"36px"}>
                              <Text size="sm" color="gray.9" fw={500}>
                                Нэмэлт файл хавсаргах:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <FileUpload
                              value={values?.files}
                              error={errors?.files && !values?.files}
                              onChange={(e) => setFieldValue("files", e?.url)}
                              name="files"
                              placeholder={values?.files ? `файл: ${values?.files?.slice(66, 96)}` : "Хавсралт"}
                            />
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <Group align="center" h={"36px"}>
                              <Anchor size="sm" fw={500}>
                                <Flex align={"center"} gap={4}>
                                  <IconFile size={18} /> <span>Файл нэмэх</span>
                                </Flex>
                              </Anchor>
                            </Group>
                          </Grid.Col>
                          <Divider h={"lg"} mt="md" />
                          <Grid.Col span={12}>
                            <Divider h="lg" />
                            <Group position="right">
                              <Button loading={loading} leftIcon={<IconCoins />} type="submit">
                                Төлбөр батлах
                              </Button>
                            </Group>
                          </Grid.Col>
                        </>
                      </>
                    </>
                  )}
                </Grid>
              </>
            );
          }}
        </Form>
      </FormLayout>
    </Paper>
  );
}
