import { Anchor, Box, Button, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Modal, Paper, Text, createStyles } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useSWR from "swr";
import * as yup from "yup";
import { ScfProgramApi, ScfRequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateFormat } from "../../utils/date";
import { InvInfoTable } from "../apply-funding/inv-info-table";
import { ApplyFundingPinForm } from "../apply-funding/pin-form";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Tag } from "../tags";

const schema = yup.object({
  contractFile: yup.string().required("Заавал бөглөнө!"),
  invoices: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required("Заавал бөглөнө!"),
        requestedAmount: yup.number()?.moreThan(0, "0-с их үнэ байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      }),
    )
    .required("Заавал бөглөнө!"),
  programId: yup.string().required("Заавал бөглөнө!"),
});

type IFormData = {
  programId: string | undefined;
  invoices: {
    id: string | undefined;
    requestedAmount: number | undefined;
  }[];
  contractFile: string | undefined;
};

export const DrawerFormSupplier = ({ setAction, action, reloadTable }: { action: any; setAction: (e: any) => void; reloadTable: () => void }) => {
  const { theme } = useStyle();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [pinAction, setPinAction] = useState<any[]>([]);
  const params = useParams();
  const { invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const type = user?.currentBusiness?.type;

  const [data] = useState<IFormData>({
    contractFile: "file bn",
    invoices:
      action[1] &&
      action[1]?.map((item: any) => {
        return {
          ...item,
          requestedAmount: item?.amountToPay,
        };
      }),
    programId: (action[1] && action[1]?.programId) || params.id,
  });

  const { data: detail, isLoading } = useSWR<any>(
    "swr.ScfProgramApi.get",
    async () => {
      try {
        const res = await ScfProgramApi.getScf(action[1]?.programId || params.id!);
        return res;
      } catch (error: any) {
        message.error(error?.message);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const availableCheck = (e1: number, e2: number) => {
    if (e1 > e2) {
      return <IconX color="red" />;
    } else return <IconCheck color={"green"} />;
  };

  const onRequestSend = async (values: IFormData) => {
    setLoading(true);
    console.log("onRequestSend: ", {
      programId: values?.programId!,
      contractFiles: [values?.contractFile || "/"],
      invoices: values?.invoices?.map((item: any) => {
        return {
          id: item.id,
          requestedAmount: item.requestedAmount,
        };
      }),
    });
    try {
      await ScfRequestApi.supplierLedCreate({
        programId: values?.programId!,
        contractFiles: [values?.contractFile || "/"],
        invoices: values?.invoices?.map((item: any) => {
          return {
            id: item.id,
            requestedAmount: item.requestedAmount,
          };
        }),
      });
      message?.success("Санхүүжилтийн хүсэлт амжилттай илгээлээ.");
      reloadTable();
      setLoading(false);
      setAction([]);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
      setPinAction([]);
    }
  };

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      setPinAction(["pin", values]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
    setLoading(false);
  };

  const now = new Date();

  console.log("supplier drawer =========================>");
  console.log("data: ", data);
  console.log("action: ", action);
  console.log("<=========================");

  if (isLoading)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );
  else
    return (
      <FormLayout
        my={0}
        title="Санхүүжилтийн хүсэлт"
        subTitle="Нэхэмжлэх барьцаалах хөтөлбөрөөр санхүүжилтийн хүсэлт илгээх"
        extra={[<CloseButton onClick={() => setAction([])} key={0} />]}>
        <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
          {({ values, setFieldValue }) => {
            return (
              <Paper>
                <Divider />
                <Paper>
                  <Box py={"md"}>
                    <Grid gutter={"lg"}>
                      <Grid.Col span={12}>
                        <Divider
                          label={
                            <Text c="golomt" fw={600} size={"md"}>
                              Санхүүжилтийн хүсэлтийн мэдээлэл
                            </Text>
                          }
                        />
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Хүсэлтийн код:">
                          <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                            Авто үүснэ
                          </Text>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Хүсэлт гаргасан бизнес:">
                          <Anchor>
                            <Flex gap={8}>
                              <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                                {user?.currentBusiness?.profileName || ""},
                              </Text>
                              <Text color="gray" fz={"sm"} fw={600}>
                                # {user?.currentBusiness?.refCode || ""}
                              </Text>
                            </Flex>
                          </Anchor>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Хүсэлт гаргасан:">
                          <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                            {user?.lastName ? user?.lastName[0] + ". " : ""} {user?.firstName}, {dateFormat(`${now}`)}
                          </Text>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Хөтөлбөрийн нэр:">
                          <Anchor>
                            <Flex gap={8} wrap={"wrap"} rowGap={"0"}>
                              <Text color={theme.primaryColor} fz={"sm"} fw={600}>
                                {detail?.name || "-"},
                              </Text>
                              <Text fz={"sm"} fw={600} color="dimmed">
                                # {detail?.refCode || "-"}
                              </Text>
                            </Flex>
                          </Anchor>
                        </Input.Wrapper>
                      </Grid.Col>

                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                          <div>
                            <Flex columnGap="xs" wrap={"wrap"}>
                              <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                                {detail?.product?.name || "-"},
                              </Text>
                              <Text fz={"sm"} fw={600} color="dimmed">
                                # {detail?.product?.refCode || "-"}
                              </Text>
                            </Flex>
                          </div>
                        </Input.Wrapper>
                      </Grid.Col>

                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Хүсэлтийн төлөв:">
                          <div>
                            {Tag(
                              invoicePaymentStatus?.find((item: IReference) => item.code === detail?.paymentStatus)?.color || "gray",
                              invoicePaymentStatus?.find((item: IReference) => item.code === detail?.paymentStatus)?.name || "Драфт",
                            )}
                          </div>
                        </Input.Wrapper>
                      </Grid.Col>

                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Боломжит санхүүжилтийн лимит:">
                          <Flex gap={8}>
                            {availableCheck(
                              values?.invoices?.reduce((itr: any, acc: any) => itr + acc.requestedAmount, 0),
                              detail?.availableAmount,
                            )}
                            <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                              {currencyFormat(detail?.availableAmount || 0)}
                            </Text>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                      {/* amountToPay */}
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Сонгосон нэхэмжлэхийн үлдэгдэл:">
                          <Flex gap={8}>
                            <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                              {currencyFormat(values?.invoices?.reduce((itr: any, acc: any) => itr + acc.amountToPay, 0) || 0)}
                            </Text>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Нэхэмжлэхийн тоо:">
                          <Flex gap={8}>
                            <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                              {values?.invoices?.length || 0}
                            </Text>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Санхүүжих хүсэлтийн дүн:" description="(Хүснэгт дэх хүсэлтийн баталсан дүнгийн нийлбэр)">
                          <Flex gap={8}>
                            <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                              {currencyFormat(values?.invoices?.reduce((itr: any, acc: any) => itr + acc.requestedAmount || 0, 0) || 0)}
                            </Text>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Төлөхөөр тооцсон шимтгэл:">
                          <Flex gap={8}>
                            <IconCheck />
                            <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                              {/* {currencyFormat(
                                values?.invoices?.reduce(
                                  (itr: any, acc: any) =>
                                    itr +
                                    ((acc?.requestedAmount || 0) *
                                      ((detail.product?.suppDisbursementFee || 0) + (detail.product?.suppFee || 0) / (detail.product?.suppCalculateDay || 0))) /
                                      100 +
                                    (detail.product?.suppAppFee || 0),
                                  0,
                                ) || 0,
                              )} */}

                              {/* Санхүүжилтийн шимтгэл ((record?.requestedAmount || 0) * ((product?.suppDisbursementFee || 0) + (product?.suppFee || 0) /
                              (product?.suppCalculateDay || 0))) / 100 + (product?.suppAppFee || 0)
                              <br />
                              Өргөдлийн хураамж product?.buyerAppFee
                              <br />
                              Олголтын шимтгэл record?.requestedAmount || 0) * (product?.buyerDisbursementFee || 0)) / 100
                              <br /> */}

                              {currencyFormat(
                                values?.invoices?.reduce(
                                  (itr: any, acc: any) =>
                                    (itr || 0) +
                                    (type === "BUYER"
                                      ? ((acc?.requestedAmount * detail.product?.buyerFee) / detail.product?.buyerCalculateDay / 100) * detail.product.buyerTerm
                                      : ((acc?.requestedAmount || 0) *
                                          ((detail.product?.suppDisbursementFee || 0) +
                                            (detail.product?.suppFee || 0) / (detail.product?.suppCalculateDay || 0))) /
                                          100 +
                                        (detail.product?.suppAppFee || 0)) +
                                    (type === "BUYER"
                                      ? ((acc?.requestedAmount || 0) * (detail.product?.buyerDisbursementFee || 0)) / 100
                                      : ((acc?.requestedAmount || 0) * (detail.product?.suppDisbursementFee || 0)) / 100) +
                                    (type === "BUYER" ? detail?.product?.buyerAppFee || 0 : detail?.product?.suppAppFee || 0),

                                  0,
                                ),
                              )}
                            </Text>
                          </Flex>
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col md={12} lg={6} xl={4}>
                        <Input.Wrapper label="Санхүүжилт авах данс:">
                          <Anchor>
                            <Flex gap={8}>
                              <Text fz={"sm"} fw={600} color={theme.primaryColor}>
                                {detail?.anchor?.inAcc?.number},
                              </Text>
                              <Text color="gray" fz={"sm"} fw={600}>
                                {detail?.anchor?.inAcc?.name},
                              </Text>
                              <Text color="gray" fz={"sm"}>
                                {detail?.anchor?.inAcc?.bankName}
                              </Text>
                            </Flex>
                          </Anchor>
                        </Input.Wrapper>
                      </Grid.Col>
                      {/* <Grid.Col md={12} lg={6} xl={4}>  // Гэрээ баталгаажилт - баталгаажаагүй гэж байгааг нууяа test:mode
                        <Input.Wrapper label="Гэрээ баталгаажилт:">
                          <Anchor>
                            {false ? (
                              <Text fz={"sm"} fw={600} color={"green"}>
                                <Flex gap={8}>
                                  <IconCheck /> Баталгаажсан
                                </Flex>
                              </Text>
                            ) : (
                              <Text fz={"sm"} fw={600} color={"red"}>
                                <Flex gap={8}>
                                  <IconX /> Баталгаажаагүй
                                </Flex>
                              </Text>
                            )}
                          </Anchor>
                        </Input.Wrapper>
                      </Grid.Col> */}
                      <Grid.Col span={12}>
                        <Divider
                          label={
                            <Text c="golomt" fw={600} size={"md"}>
                              Нэхэмжлэхийн мэдээлэл
                            </Text>
                          }
                        />
                      </Grid.Col>
                    </Grid>
                  </Box>
                  <InvInfoTable setFieldValue={setFieldValue} invoices={values.invoices} data={detail} />
                  <Divider my={"lg"} />
                  <Button loading={loading} type="submit">
                    Хүсэлт илгээх
                  </Button>
                </Paper>
              </Paper>
            );
          }}
        </Form>
        <Modal size={400} centered title="SCF хүсэлт илгээх" withCloseButton={false} opened={pinAction[0] === "pin"} onClose={() => setPinAction([])}>
          <Divider mb={"xs"} />
          <ApplyFundingPinForm action={pinAction} submit={(data) => onRequestSend(data)} onClose={() => setPinAction([])} />
        </Modal>
      </FormLayout>
    );
};

const useStyle = createStyles(() => ({}));
