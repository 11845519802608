import { ActionIcon, Anchor, Group, Text, Tooltip } from "@mantine/core";
import { IconFileText } from "@tabler/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ScfProgramApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";

export const BuyerMasterContractList = () => {
  const navigate = useNavigate();
  const { programStatus } = useSelector((state: { general: IGeneral }) => state.general);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<any>({ financeType: "SCF" });
  const columns = useHeader({
    programStatus,
    onClick: (key, item) => {
      switch (key) {
        case "details":
          navigate(`/finance-program/${item.id}`);
          break;

        default:
          break;
      }
    },
  });

  return <Table minWidth={2400} name="requests.list.buyer.led" columns={columns} filters={filters} loadData={(data) => ScfProgramApi.list(data!)} />;
};

const useHeader = ({ onClick, programStatus }: { onClick: (e1: string, e2: any) => void; programStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
            <IconFileText />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.name || "-"}</Text>
          <Anchor># {record?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    render: (record) => {
      return (
        <Text w="max-content">
          <Text>{record?.product?.name || "-"}</Text> <Anchor># {record?.product?.refCode || "-"}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Хөтөлбөр статус",
    render: (record) => {
      return Tag(
        programStatus.find((item: IReference) => item.code === record.programStatus)?.color || "",
        programStatus.find((item: IReference) => item.code === record.programStatus)?.name || "-",
      );
    },
  },
  {
    title: "SME тоо",
    render: (record) => {
      return <Group w={"max-content"}>{record?.smeCount}</Group>;
    },
  },
  {
    title: "Баталсан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record?.programLimit || 0)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Боломжит лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.availableAmount)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Ашигласан лимит",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.utilizedAmount)}
        </Text>
      </Group>
    ),
  },
  {
    title: "Олголт хүлээж буй",
    render: (record) => (
      <Group position="right">
        <Text w={"max-content"} align="end">
          {currencyFormat(record.toDisburseAmount)}
        </Text>
      </Group>
    ),
  },
];
